<template>
  <div class="row">
    <div class="col mb-3">
      <form id="search">
        Filtrer par paragraphe
        <input name="query" v-model="searchQuery">
      </form>
    </div>
    <div class="col">
      <a class='btn btn-sm btn-outline-dark' @click="onexport"><i class="fas fa-save"></i> Exporter vers Excel</a>
      <a class="btn btn-sm btn-outline-dark" @click="showMasked=!showMasked"><i :class="getEyeBtn(showMasked)"></i> $ Masqués</a>
      <a class="btn btn-sm btn-outline-dark" @click="dpgfOnly=!dpgfOnly"><i :class="getEyeBtn(!dpgfOnly)"></i> DPGF</a>

    </div>
    <quantitatif-grid
      :griddata="gridData"
      :columns="gridColumns"
      :filter-key="searchQuery"
      :tva-options="tvaOptions"
      :unites="unites"
      :showMasked="showMasked"
      :dpgfOnly="dpgfOnly"
      @unite_changed="uniteChanged"
    >
    </quantitatif-grid>
  </div>
</template>

<script>
    import QuantitatifGrid from './QuantitatifGrid'
    import XLSX from 'xlsx'
    import axios from 'axios'

    export default {
        name: "Quantitatifs",
        components: {QuantitatifGrid},
        props: {
            document_id: Number,
            tiers_id:Number,
        },
        data() {
            return {
                treeData: [],
                gridData: [],
                gridColumns: {
                   // 'patterned_path': 'Chapitre',
                    'full_path':'full_path',
                    'nom': 'Désignation',
                    'unite': 'Unité',
                    'quantite': 'Quantité',
                    'prix': 'Prix/Unité',
                    'total': 'Total HT',
                    'tva': '% TVA',
                    'mttva': 'TVA',
                    'totalTTC': 'Total TTC',
                },
                searchQuery: '',
                tvaOptions: [],
                unites: [],
                showMasked: false,
                dpgfOnly: true,
            }
        },
        computed: {
        },
        methods: {
            getTreeData: async  function () {
                //var data = JSON.parse(document.getElementById('articles').textContent)
                let rq = await axios.get('/api/document/'+this.document_id.toString()+'/get_tree', { params: { dpgf:'', tiers:this.tiers_id} });
                let data = rq.data.results;
                this.treeData = data
                this.gridData = this.getGridData()
            },
            getGridData() {
                var that = this
                var data = [];
                function add_node_to_grid(elt) {
                    //Ici on sélectionne le bon prix selon le tiers == ? TODO et quid du  document ==> ça doit être dans l'api qu'on gère ça ?
                    data.push({
                        id: elt.paragraphe.id,
                        patterned_path: elt.patterned_path,
                        full_path: elt.paragraphe.full_path,
                        nom: elt.paragraphe.nom,
                        niveau: elt.paragraphe.niveau,
                        unite: elt.paragraphe.unite === undefined || elt.paragraphe.unite === '' ? '' : elt.paragraphe.unite,
                        quantite: elt.paragraphe.quantitatifs === undefined || elt.paragraphe.quantitatifs === '' ? undefined : elt.paragraphe.quantitatifs.find(a => a.tiers == that.tiers_id),
                        prix: elt.paragraphe.prix === undefined || elt.paragraphe.prix === '' ? '' : elt.paragraphe.prix.find(a => a.tiers == that.tiers_id),
                        //total: elt.paragraphe.prix === '' || elt.quantite === undefined || elt.quantite === '' ? '' : elt.prix.valeur * elt.quantite.valeur,
                        tva: elt.paragraphe.tva === undefined  || elt.paragraphe.tva === '' ? '' : elt.paragraphe.tva,
                        totalHT: 0,
                        masked: elt.masked,
                    })
                    elt.children.forEach((elt) =>{
                        add_node_to_grid(elt)
                    })
                }
                this.treeData.forEach((elt) => {
                    add_node_to_grid(elt)
                })
                return data;
            },
            getTvaOptions: async function () {
                // TODO set Tva VALUES with axios ,
                let rq = await axios.get('/api/tva')
                this.tvaOptions= rq.data
            },
            getUnites: function () {
                axios({
                    url: '/api/unites',
                    method: 'get',
                })
                    .then(response => {
                        this.unites= response.data
                    })
            },
            onexport: function () { // On Click Excel download button

                // export json to Worksheet of Excel
                // only array possible
                //console.log('exporting data')
                var ws = XLSX.utils.json_to_sheet(this.dataAsTable)
                // A workbook is the name given to an Excel file
                var wb = XLSX.utils.book_new() // make Workbook of Excel
                var wscols = [
                    {wch: 7},
                    {wch: 70},
                    {wch: 8},
                    {wch: 8},
                    {wch: 10},
                    {wch: 10},
                    {wch: 10},
                ];

                ws['!cols'] = wscols;
                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, ws, 'quantitatifs')

                // export Excel file
                XLSX.writeFile(wb, 'quantitatifs.xlsx') // name of the file is 'book.xlsx'
            },
            getEyeBtn: function (element) {
                return !element ? 'fas fa-eye' : 'fas fa-eye-slash'
            },
            uniteChanged: async function(line_changed) {
                let paragraphe_id = line_changed.data.id.toString()
                let rq = await axios.get('/api/document/'+this.document_id.toString()+'/get_quantite', {params: {paragraphe: paragraphe_id}})
                let rq2 = await axios.get('/api/document/'+this.document_id.toString()+'/get_prix', {params: {paragraphe: paragraphe_id}})
                this.gridData = []
                await this.getTreeData() //TODO reloading all tree is too much and is not working ?
                console.log('set price for ' + paragraphe_id)
            }
        },
        created: function() {
            // get unités from api call and set unite property
            this.getUnites();
            this.getTreeData();
            this.getTvaOptions()

        },
    }
</script>

