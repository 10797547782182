import Vue from 'vue'
import Quantitatifs from "./components/Quantitatifs.vue";
import GenericAutocomplete from "./components/GenericAutocomplete"
import ParagraphesAutocomplete from "./components/ParagraphesAutocomplete.vue"
import StructureTree from "./components/StructureTree.vue"


import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor)
import RessourceEditor from "./components/RessourceEditor.vue"
import RessourcesParagraphe from "./components/RessourcesParagraphe.vue"


//Vue.config.devtools = true
//Vue.config.productionTip = false;

Vue.filter('currency', function (value) {
  if (!value) return value
  let result = value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
  //console.log('filtering ' + value + ' to ' + result)
  return result
});

new Vue({
  el: '#app',
  //render: h => h(Quantitatifs),   Laisse le soin d'utiliser les composants dans le template django
  components: {
    Quantitatifs,  ParagraphesAutocomplete, GenericAutocomplete, RessourceEditor, RessourcesParagraphe, StructureTree
  }
})
Vue.config.devtools = true;
