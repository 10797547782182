<template>
  <div>
    <my-vue-select :options="items"  :resetOnOptionsChange="true"></my-vue-select>
  </div>
</template>

<script>
  import axios from 'axios';
  import MyVueSelect from './MyVueSelect'

  export default {
    name: "ParagraphesAutocomplete",
    components: {MyVueSelect},
    props: {
      structure:
        {
          type: Number,
          required: true
        },
    },
    data : function () {
      return {
        items: [],
        response:'Response',
      }
    },
    methods: {
      getData() {
        axios({
          url: '/api/paragraphe',
          method: 'get',
          params: {
            limit: 10000,
            structure: this.structure
          }
        }).then(response => {
          let items = response.data.results.map(function(a) {return {id: a.id, nom: a.full_path + ' - '+ a.nom}})
          items.sort(function(a,b) {if (a.nom > b.nom) {return 1} else {return -1}})
          this.items = items

          this.response = response.data
        }).catch(e => {
          console.error('Api Call fails' + e.message)
        })
      },
      onInput() {
        console.log('input')
      },
      onStructureChange(selected) {
        console.log('structure changed' + selected)
        this.structure = selected
        this.items = this.getData()
        this.showStructureSelect=false
      }
    },
    created() {
      this.getData()
    }

  }
</script>

<style scoped>

</style>
