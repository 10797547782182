<template>
  <select class="no-border" v-model="selected" @change="$emit('input',selected)">
    <option v-for="opt in this.options" :value="getOptId(opt)"> {{ getOptValue(opt) }} </option>
  </select>
</template>

<script>
    export default {
        name: "SelectComponent",
        props: {
            entity: String,
            options: Array,
            data_field_name: {
                type: String,
                default: 'value',
            },
            // option can be a string or an object formed like {id: int, value: String}
            val: String,
        },
        data: function() {
            return {
                selected: this.val
            }
        },
        computed: {

        },
        methods: {
            getOptId: function(opt) {
                return opt && typeof opt  === 'object' ? opt.id : opt
            },
            getOptValue: function(opt) {
                return typeof opt  === 'object' ? opt[this.data_field_name] : opt
            }
        }
    }
</script>

<style scoped>
  .no-border {
    border: 0px
  }
</style>
