<script>
  import MyEditor from './MyEditor'
  import axios from 'axios'

  export default {
    name: "RessourceEditor",
    extends: MyEditor,    // Héritage de l'éditeur par défaut
    props: {
      id: {
        type: Number,
        required: true,
      }
    },
    methods: {
      getContent() {
        var that = this
        axios({
          url: '/api/ressource/'+that.id.toString(),
          method: 'get',
          params: {}
        }).then(response => {
          that.editorData = response.data.ckhtml
        }).catch(e => {
          console.error('error retrieving ressource data from id '+that.id.toString())
        })
      },

      saveContent() {
        var that = this
        //console.log('Save ressource content')
        axios({
          url: '/api/ressource/'+that.id.toString()+'/',
          method: 'patch',
          params: {},
          data: {'ckhtml': that.editorData},
          headers: {
            'X-CSRFToken': document.getElementsByName("csrfmiddlewaretoken")[0].value
          }
        }).then(response => {
          console.log('Content posted')
        }).catch(e => {
          console.error('error retrieving ressource data from id '+that.id.toString())
        })
      }
    },
    created() {
      this.getContent()
    }
  }
</script>

