<template>
  <tr :class="getClass" v-if="!isMasked && showDpgfOnly">
    <template v-for="(val, key) in cols">
      <td :class="getTdClass(key)" >
        <template v-if="['quantite','prix'].includes(key)">
          <template v-if="isEditable && isDpgf && colEditable(key)">
            <input
              @focus="$event.target.select()"
              @change="saveValue(key)"
              type="number" step="0"
              v-model="data[key].valeur">
          </template>
          <template v-else>

          </template>
        </template>


        <!--        <my-vue-select v-else-if="key == 'unite'" :options="unites" :value="getData(key)" @input="saveUnite" :selectOnTab="true" :transition="''"></my-vue-select>-->
        <simple-select v-else-if="key === 'unite' && colsEditable.indexOf('unite') !== -1" :val="getOptionId(key)" :options="unites" @input="saveUnite" :data_field_name="'code'"> </simple-select>
        <simple-select v-else-if="isEditable && key === 'tva' && colsEditable.indexOf('tva') !== -1" :val="getOptionId(key)"  :options="tvaOptions" @input="saveTva" :data_field_name="'taux'"> </simple-select>

        <template v-else-if="key === 'patterned_path'" >
          <div class="pointer" @click="showRessource=true" >{{ getData(key) }}</div>
          <!--          <div class="full_path" @click="toggleShowRessource" >{{ getData(key) }}-->
          <ressources-paragraphe @clickclose="showRessource=false" v-if="showRessource" :id="getData('id')" :editable="false" :document="getDocumentId" :header="getTitle" class="ressource-paragraphe" ></ressources-paragraphe>

        </template>
        <template v-else-if="key ==='unite' && isDpgf"  >
          {{ getData(key) | currency }}
        </template>
        <template v-else>
          {{ getData(key) | currency }}
        </template>
      </td>
    </template>
  </tr>
</template>

<script>
    import axios from 'axios';
    import MyVueSelect from './MyVueSelect'
    import SimpleSelect from './SimpleSelect'
    import MyEditor from "./MyEditor";
    import RessourcesParagraphe from "./RessourcesParagraphe";

    export default {
        name: "QuantitatifGridLine",
        components: {RessourcesParagraphe, MyVueSelect, SimpleSelect, MyEditor},
        props: {
            data: Object,
            cols: Object,
            colsEditable: Array,
            tvaOptions:Array,
            unites:Array,
            showMasked: Boolean,
            dpgfOnly: Boolean,
        },
        created: function () {
        },
        data: function () {
            return {
                showRessource: false,
            }
        },
        computed: {
            getTitle: function() {
                return this.data.patterned_path + '-'+this.data.nom
            },
            getClass: function () {
                let masked = this.data.masked ? 'masked' : ''
                return 'niv' + this.data.niveau + ' ' + masked
            },
            getCols: function () {
                return this.cols
            },
            hasQuantity: function () {
                return typeof this.data.quantite === 'object'
            },
            hasPrice: function () {
                return typeof this.data.prix === 'object'
            },
            hasTva: function () {
                return this.data.tva  && typeof this.data.tva === 'object'
            },
            isMasked: function () {
                return !this.showMasked && this.data.masked
            },
            hasRealUnite: function() {
                return this.data.unite &&  this.data.unite.code !== '-'
            },
            isDpgf: function () {
                return this.data.unite && this.data.unite.code !== ''
            },
            isEditable: function () {
                return  this.hasRealUnite && this.hasQuantity && this.hasPrice
            },
            total: function () {
                var that = this;
                var tot = '';
                if (this.hasQuantity && this.isDpgf ) {
                    tot = this.getData('quantite') * this.getData('prix')
                }
                this.data['totalHT'] = tot
                return tot
            },
            totalTTC: function () {
                //return (this.total * (100+this.tva)/100)
                var ttc= ''
                if  (this.hasQuantity && this.isDpgf) {
                    ttc = (this.getData('quantite') * this.getData('prix') * (100 + this.getData('tva')) / 100)
                }
                return ttc
            },
            mttva: function () {
                if (this.hasQuantity && this.isEditable && this.isDpgf) {
                    return this.totalTTC - this.total
                }
            },
            showDpgfOnly: function () {
                return !(this.dpgfOnly && !this.isDpgf)
            }
        },
        methods: {
            colEditable: function (col) {
                return this.colsEditable.indexOf(col) >= 0 && this.getId(col) > 0
            }
            ,
            colOptions: function (col) {
                return this.hasQuantity  && ((col === 'tva')) // || (col=== 'unite'))
            }
            ,
            getOptions: function (col) {
                // TODO implémenter
                if (col === 'tva') {
                    return this.tvaOptions;
                }
            },
            getEntity: function (col) {
                switch (col) {
                    case 'quantite':
                        return 'quantitatif';
                        break;
                    default:
                        return col
                }
            }
            ,
            getId: function (col) {
                switch (col) {
                    case 'quantite':
                        return this.data.quantite.id
                    case 'prix':
                        return this.data.prix.id
                    default:
                        return this.data.id
                }
            }
            ,
            getField: function (col) {
                // renvoie le champs à poster en fonction de la colonne
                switch (col) {
                    case 'quantite':
                        return 'valeur'
                    case 'prix':
                        return 'valeur'
                    default:
                        return col
                }
            }
            ,
            getData: function (col) {
                switch (col) {
                    case 'quantite':
                        return this.hasQuantity && this.isEditable ? this.data.quantite.valeur : ''
                    case 'prix':
                        return this.hasPrice ? this.data.prix.valeur : ''
                    case 'unite':
                        return this.data.unite === null || this.data.unite === '' ? '' : this.data.unite.code
                    case 'total':
                        return this.total
                    case 'totalTTC':
                        return this.hasQuantity && this.hasPrice ? this.totalTTC : ''
                    case 'mttva':
                        return this.hasQuantity ? this.mttva : ''
                    case 'tva':
                        return this.hasTva ? this.data.tva.taux : ''
                    default:
                        return this.data[col]
                }
            }
            ,
            getOptionId: function (col) {
                if (this.data[col] && typeof this.data[col] === 'object') {
                    return this.data[col].id
                }
            }
            ,
            valueChanged: function (evt) {
                /* TODO not used yet */
                this.qty = evt.textContent
                this.$emit('change', this.qty);
            }
            ,
            selectAll(tgt) {
                if (this.qty !== null && this.colEditable(tgt.getAttribute('data-col'))) {
                    document.execCommand('selectAll', false, null)
                }
            }
            ,
            saveValue(key, value) {
                var entity, id, field, value
                if (key == 'prix' || key == 'quantite') {
                    entity = this.getEntity(key)
                    id = this.data[key].id
                    field = this.getField(key)
                    value = this.data[key].valeur
                } else  {
                    entity = 'paragraphe'
                    id = this.data.id
                    field = this.getField(key)
                    value = value || this.getOptionId(key)
                }
                this.patch(entity, id, field, value, key)
            },
            saveUnite: async function(value) {
                if (!this.isEditable && value && value !== '-' ) {
                    this.$emit('unite_changed', this)
                }
                this.data.unite = this.unites.find(a => a.id===value)
                this.saveValue('unite',value)

            },
            saveTva(value) {
                //retrieve
                this.data.tva = this.tvaOptions.find(a => a.id===value)
                this.saveValue('tva')
            }
            ,
            patch(entity, id, field, value, key) {
                ui.showMessage('Enregistrement en cours')
                //console.log('Patching ' + entity)
                var that = this

                axios({
                    url: '/api/' + entity + '/' + id + '/',
                    method: 'patch',
                    data: {
                        [field]: value
                    },
                    headers: {
                        'X-CSRFToken': $("[name=csrfmiddlewaretoken]").val()
                    }
                }).then(response => {
                    ui.showMessage('Enregistrement OK ', 'alert-success')
                }).catch(e => {
                    ui.showMessage('Enregistrement en erreur !!', 'alert-danger')
                })
            }
            ,
            toggleShowRessource: function() {
                this.showRessource = !this.showRessource
            },
            getTdClass: function(key) {
                return (this.isEditable && this.colEditable())? 'editable':'non-editable';
            },
        }
        ,
    }
</script>

<style scoped>
  /*
    tr.niv1 td[column='nom'], tr.niv1 td[column='nom'] {
      font-weight: bold;
    },*/

  table thead tr {
    position: relative;
    display: block;

  }
  table tbody {
    overflow: scroll;
    height: 100vh;
  }

  tr.niv1>td{
    font-weight: bolder;
    background-color: lightgrey;
  }

  tr.niv2>td {
    background-color: #eeeeee;
  }

  tr.niv3>td {
    background-color: white;
  }

  tr.niv2 td[column='chapitre'] {
    padding-left: 20px;
  }

  tr.niv3 td[column='chapitre'] {
    padding-left: 40px;
  }

  tr.niv4 td[column='chapitre'] {
    padding-left: 3em;
  }

  tr.niv5 td[column='chapitre'] {
    padding-left: 4em;
  }

  tr.niv6 td[column='chapitre'] {
    padding-left: 5em;
  }

  tr.niv7 td[column='chapitre'] {
    padding-left: 6em;
  }

  .total, .totalTTC {
    font-weight: bold;
  }

  .total, .totalTTC, .mttva,  .tva, .number {
    text-align: right;
  }

  td {
    position: relative;
    vertical-align: middle;
  }

  td input, td select{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    border: none;
    padding: 10px;
    box-sizing: border-box;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
  .ressource-paragraphe {
    position: absolute;
    height: 30vh;

    z-index: 1000;
    background-color: #EEEEEE;
    overflow: auto;
  }
  div.pointer {
    background-color: transparent;
    cursor: pointer;
  }
  .masked {
    text-decoration: line-through;
    font-style: italic;
    color: #626262;
  }
  ,
  .editable {
    background-color: #e6e6e6;
  }

</style>
