<template>
  <div>
    <my-vue-select :options="items"  :resetOnOptionsChange="true"></my-vue-select>
  </div>
</template>

<script>
  import axios from 'axios';
  import MyVueSelect from './MyVueSelect'

  export default {
    name: "GenericAutocomplete",
    components: {MyVueSelect},
    props: {
      apiurl: {
          type:String,
          required: true,
        },
      apiparams: {
        type: Object,
        required: false,
        default: {etat:'MOD'}
      },
      sortresults: {
        type: Boolean,
        default: true,
      }
    },
    data : function () {
      return {
        items: [],
      }
    },
    methods: {
      getData() {
        axios({
          url: this.apiurl,
          method: 'get',
          params: this.apiparams,
        }).then(response => {
          let items = response.data.results.map(function(a) {return {id: a.id, nom: a.nom}}) // find a way to parameter this (liste des champs à faire figurer dans la chaine de caractères
          items.sort(function(a,b) {if (a.nom > b.nom) {return 1} else {return -1}})
          this.items = items
        }).catch(e => {
          ui.showMessage('Api Call fails' + e.message)
        })
      },
      onInput() {
        console.log('input')
      }
    },
    created() {
      this.getData()
    }

  }
</script>

<style scoped>

</style>
