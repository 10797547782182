<template>

</template>

<script>
  import axios from 'axios'
  export default {
    name: "GdAxios",
    components: {axios},
    methods: {
      getParagraphes(params, target) {
        axios({
          url: '/api/paragraphe',
          method: 'get',
          params: params
        }).then(response => {
          let items = response.data.results.map(function(a) {return {id: a.id, nom: a.code + ' - '+ a.nom}})
          items.sort(function(a,b) {if (a.nom > b.nom) {return 1} else {return -1}})
          this.items = items
          target = response.data
        }).catch(e => {
          console.log('Api Call fails' + e.message)
        })

      }
    }
  }
</script>

<style scoped>

</style>
