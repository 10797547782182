<script>
  import vSelect from 'vue-select'

  import 'vue-select/dist/vue-select.css';
  export default {
    name: "MyVueSelect",
    extends: vSelect,            //Héritage de composant
    template: vSelect.template,   //Héritage de template
    props: {
      label: {
        default:'nom'
      }
    },
    data: function() {
      return {
      }
    },
    methods: {
      getData() {
        // implémenter un appel générique à l'api qui renvoie entité, id et nom (pour l'autocomplete)
      }

    }
  }
</script>

<style scoped>

</style>
