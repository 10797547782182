<template>
  <ul>
    <structure-item
      class="item"
      :item="treeData"
      @make-folder="makeFolder"
      @add-item="addItem"
    ></structure-item>
  </ul>
</template>

<script>
    import Vue from 'vue'
    import StructureItem from './StructureItem'
    import GdAxios from "../GdAxios";
    export default {
        name: "StructureTree",
        components: {GdAxios, StructureItem},
        props: {
          structure_id: Number,
        },
        data () {
            return {
                treeData: {},
            }
        },
        methods: {
            makeFolder: function (item) {
                Vue.set(item, 'children', [])
                this.addItem(item)
            },
            addItem: function (item) {
                item.children.push({
                    name: 'new stuff'
                })
            }
        },
        created: {
            ///GdAxios.getParagraphes
        }
    }
</script>

<style scoped>

</style>
