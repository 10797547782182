<template>
  <div class="col-12">
    <table id="bordereau" class="table table-bordered" >
      <thead>
      <tr>
        <th>Chapitre</th><th>Full_Path</th>
        <th>Titre</th>

        <th @click="toggleEditable('unite')" class="toggleable" title="Activer/Désactiver l'édition">Unité</th>
        <th @click="toggleEditable('quantite')" class="toggleable" title="Activer/Désactiver l'édition">Quantité</th>
        <th @click="toggleEditable('prix')" class="toggleable" title="Activer/Désactiver l'édition">Prix</th>
        <th>Total HT</th>
        <th @click="toggleEditable('tva')" class="toggleable" title="Activer/Désactiver l'édition">%TVA</th>
        <th>TVA</th><th>Total TTC</th>

        <!--<th v-for="(val,key) in columns"
            :class="{ active: sortKey == key }">
          {{ val | capitalize }}
          <span class="arrow" :class="sortOrders[key] > 0 ? 'asc' : 'dsc'">
          </span>
        </th>-->
      </tr>
      </thead>
      <tbody >

      <tr v-if="!hasData" class="total"><td colspan="10"> Chargement en cours... merci de patienter <img src="/static/ajax-loader.gif" /></td></tr>
      <tr class="total" v-if="hasData"><td class="text-left"> Totaux </td><td></td><td></td><td></td><td></td><td></td><td class="text-right">{{ total | currency }}</td><td></td><td class="text-right">{{ totalTVA | currency }}</td><td class="text-right">{{ totalTTC | currency }}</td></tr>
      <template v-for="line in filteredData">
        <quantitatif-grid-line @unite_changed="emitUniteChanged" @toggle="toggleEditable"  :data="line" :cols="columns" :colsEditable="colsEditable" @change="childChanged" :tva-options="tvaOptions" :unites="unites" :show-masked="showMasked" :dpgfOnly="dpgfOnly">
        </quantitatif-grid-line>
      </template>
      <tr class="total" v-if="hasData"><td class="text-left"> Totaux </td><td></td><td></td><td></td><td></td><td></td><td class="text-right">{{ total | currency }}</td><td></td><td class="text-right">{{ totalTVA | currency }}</td><td class="text-right">{{ totalTTC | currency }}</td></tr>
      </tbody>
    </table>
  </div>
</template>

<script>

    import QuantitatifGridLine from './QuantitatifGridLine'

    export default {
        name: "QuantitatifGrid",
        components: {QuantitatifGridLine},
        props: {
            griddata: Array,
            filterKey: String,
            columns: Object,
            tvaOptions: Array,
            unites: Array,
            showMasked: Boolean,
            dpgfOnly: Boolean,
        },
        data: function () {
            return {
                sortKey: '',
                sortOrders: {},
                colsEditable: ['quantite','prix'],
            }
        },
        mounted: function () {
        },
        computed: {
            /*      columns : function() {
                     return Object.keys(this.data[0])
                  },*/
            filteredData: function () {
                var sortKey = this.sortKey
                var filterKey = this.filterKey && this.filterKey.toLowerCase()
                var order = this.sortOrders[sortKey] || 1
                var data = this.griddata
                var cols = Object.keys(this.columns)
                /* Selecting available columns
                data = data.map(function (item) {
                  var mappeditem = {}
                  cols.forEach(function (col) {
                      mappeditem[col] = item[col]
                  })
                  return mappeditem
                })*/
                if (filterKey) {
                    data = data.filter(function (row) {
                        return Object.keys(row).some(function (key) {

                            return row['patterned_path'].indexOf(filterKey) === 0
                           // return String(row[key]).toLowerCase().indexOf(filterKey) > -1
                        })
                    })
                }
                if (sortKey) {
                    data = data.slice().sort(function (a, b) {
                        a = a[sortKey]
                        b = b[sortKey]
                        return (a === b ? 0 : a > b ? 1 : -1) * order
                    })
                }
                return data

            },
            total : function() {
                //console.log('compute total')
                var total = 0
                this.filteredData.forEach(function(elt){
                    try {
                        let add = parseFloat(elt.totalHT)
                        if (!isNaN(add))
                            total += add
                        //console.log('adding '+add.toString() + ' => ' + total.toString())
                    }
                    catch (e) {

                    }
                })
                return total
            },
            totalTTC : function() {
                //console.log('compute total TTC')
                var total = 0
                this.filteredData.forEach(function(elt){
                    try {
                        let add = parseFloat(elt.totalHT)
                        let tva = add * ((elt.tva.taux)/100)
                        if (!isNaN(add)) {
                            total += add
                            //console.log('adding ' + add.toString() + ' => ' + total.toString())
                        }
                        if (!isNaN(tva)) {
                            total += tva
                            //console.log('adding tv ' + tva.toString() + ' => ' + total.toString())
                        }
                    }
                    catch (e) {
                    }
                })
                return total
            },
            totalTVA : function() {
                return this.totalTTC  - this.total /* )).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) */
            },
            hasData: function () {
                return this.griddata.length > 0

            }
        },
        filters: {
            capitalize: function (str) {
                return str.charAt(0).toUpperCase() + str.slice(1)
            }
        },
        methods: {
            sortBy: function (key) {
                this.sortKey = key
                this.sortOrders[key] = this.sortOrders[key] * -1
            },
            getClass: function (entry) {
                return 'h' + entry.niveau + ' small'
            },
            childChanged: function(source) {
                //console.log('child event received with val: ' +source)
            },
            toggleEditable: function(key) {
                console.log('toggle Editable')
                if (this.colsEditable.indexOf(key) !== -1)  {
                    this.colsEditable  = this.colsEditable.filter(e => e !== key);
                } else  {
                    this.colsEditable.push(key)
                }
            },
            emitUniteChanged(event) {
                this.$emit('unite_changed',event)
            }
        }

    }
</script>

<style scoped>
  body {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    color: #444;
  }

  table {
    border: 1px solid #778fb9;
    border-radius: 3px;
    background-color: #fff;
    width:99.8%;
  }

  th {
    background-color: #6c9cb9;
    color: rgba(255, 255, 255, 0.66);
    /*cursor: pointer;*/
  }

  td {
    background-color: #f9f9f9;
  }

  th, td {
    min-width: 70px;
    padding: 5px 5px;
    color: #fff;
  }

  th.active {
    color: #fff;
  }

  th.active .arrow {
    opacity: 1;
  }

  /*  .arrow {
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      margin-left: 5px;
      opacity: 0.66;
    }

    .arrow.asc {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #fff;
    }

    .arrow.dsc {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #fff;
    }*/
  .total td{
    background-color: #ebeef0;
    font-weight: bold;
    color: #161616;
    font-size: 1.2em;
  }

  .toggleable {
    cursor:pointer;
  }

</style>
