<template>
  <div id="app" class="ckeditor">
    <ckeditor  @blur="blurred" :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>

  import InlineEditor from '@ckeditor/ckeditor5-build-inline';
  export default {
    name: "MyEditor",
    data() {
      return {
        editor: InlineEditor,
        editorData: '<p>En cours de chargement ...</p>',
        editorConfig: {
            uploadUrl : '/ckeditor/upload/',
            filebrowserUploadUrl : '/ckeditor/upload/',
            filebrowserBrowseUrl: '/ckeditor/browse/',

          //toolbar : [ 'Source', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote' ],
          //   { name: 'document', items: [ 'Source', '-', 'Save',  'Preview', 'Print', '-', 'Templates' ] },
          //   { name: 'editing', items: ['SelectAll',] },
          //   { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
          //   { name: 'insert', items: [ 'Image',  'Table', 'HorizontalRule',  'SpecialChar', 'PageBreak', ] },
          //   //{ name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
          //
          //   { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', ] },
          //   '/',
          //   { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
          //   { name: 'styles', items: [ 'Font', 'FontSize' ] },
          //   { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          //   { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] }
          // ]
          // The configuration of the editor.
        }
      }
    },
    methods: {
      inputStuff () {
        //console.log('input content')
      },
      blurred () {
        //console.log('blurred');
        this.saveContent();

      },
      saveContent () {
        //console.log('saving content');
        // this should be overriden by component
      }
    }
  }
</script>

<style scoped>
  .ckeditor{
    border: 1px solid blueviolet;
  }
</style>
