<template>
  <div v-if="ressources.length > 0" class="ressources" v-drag>
    <h2>{{ header }}
    <a class="btn btn-sm float-right" @click="$emit('clickclose')"> X </a>
    </h2>
    <template v-for="ressource in ressources">
      <ressource-editor v-if="editable" :id="ressource.id" class="A4"></ressource-editor>
      <div v-else >
        <div v-html="ressource.ckhtml" :class="getClass(ressource.etat)" class="A4"></div>
      </div>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import RessourceEditor from './RessourceEditor'
  import drag from '@branu-jp/v-drag'

  export default {
    name: "RessourcesParagraphe",
    components: {RessourceEditor, drag},
    props: {
      id: {
        type:Number,
        required: true,
      },
      editable: {
        default: true
      },
      document: {
        type: Number
      },
      header: {
        default: 'titre'
      }
    },
    data () {
      return {
        ressources : [],
        showMasked : false
      }
    },
    methods: {
      getData() {
        var that = this
        axios({
          url: '/api/paragraphe/'+that.id.toString()+'/get_ressources',
          method: 'get',
          params: {masked: false, document:this.document}
        }).then(response => {
          that.ressources = response.data.results
        }).catch(e => {
          console.error('error retrieving ressource data from id '+that.id.toString())
        })
      },
      getClass(etat) {
        if (etat=='MOD') {
          return 'MOD'
        } else return ''
      }
    },
    created() {
      this.getData();
    }

  }
</script>

<style scoped>
  .ressources {
    background-color: white;
    border: 1px solid grey;
  }
  .MOD {
    background-color: #fcf2f2;
  }
  .A4 {
    width: 21cm;
    /*height: 29.7cm;*/
    padding: 10px 25px;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);

    box-sizing: border-box;
  }
</style>
